import querystring from 'querystring';
import {
    DEFAULT_SORTING_HELLO_RETAIL,
    FILTER_KEY_CONTENT_TYPES,
    FILTER_KEY_PAGE,
    FILTER_KEY_SEARCH,
    FILTER_KEY_SIZE,
    FILTER_KEY_SORT,
    FILTER_KEY_TYPE,
    PAGE_SIZE,
    getFacetParamsFromUrl,
} from '~/modules/filter/helpers';
import { SearchContentTypes } from '~/modules/search';
import {
    ICombinedHelloRetailProduct,
    IHelloRetailBlogPost,
    IHelloRetailCategory,
    IHelloRetailFacetReponse,
    IHelloRetailProduct,
    IHelloRetailSearchResponse,
    IHelloRetailSelectedFacet,
    IHelloRetailSorting,
    IHelloRetailSortingOption,
    IMappedHelloRetailProduct,
    IMappedHelloRetailSuggestionProduct,
} from '~/modules/search/interfaces/suggestion';
import { isDefined } from './is-defined.helper';
import { sortUrlQuery } from './urls.helper';
import { IFullSearchResult, IIndeksRetailFacetOutput, ISearchContentResult } from '$models';
import fetcher from '$lib/fetcher';
import { formatPriceFromHelloRetail } from './price.helper';

const helloRetailKey = process.env.NEXT_PUBLIC_HELLO_RETAIL_KEY;

const baseQuery = `/api/v1/search/partnerSearch?key=${helloRetailKey}`;

export const defaultSearchType = 'all' as SearchContentTypes;
export const defaultProductCount = 3;
export const defaultStoreCount = 3;
export const defaultCategoryCount = 3;
export const defaultBlogPostCount = 3;
export const defaultSitePageCount = 3;
export const defaultBrandCount = 5;

const defaultSuggestionProductCount = 4;
const defaultSuggestionBlogPostCount = 4;
// const defaultSuggestionSitePageCount = 0;
const defaultSuggestionBrandCount = 5;

export const createSearchUrl = (
    term: string,
    searchType = defaultSearchType,
    productCount = defaultProductCount,
    storeCount = defaultStoreCount,
    categoryCount = defaultCategoryCount,
    blogPostCount = defaultBlogPostCount,
    brandCount = defaultBrandCount
) => {
    let queryString = `${baseQuery}&q=${term}&format=json`;

    switch (searchType) {
        case 'all':
            queryString += `&product_start=0&product_count=${productCount}&blog_post_start=0&blog_post_count=${blogPostCount}&site_page_start=0&site_page_count=${storeCount}&category_start=0&category_count=${categoryCount}&brands_start=0&brands_count=${brandCount}`;
            break;
        case 'products':
            queryString += `&product_start=0&product_count=${productCount}`;
            break;
        case 'categories':
            queryString += `&category_start=0&category_count=${categoryCount}`;
            break;
        case 'sitePages':
            queryString += `&site_page_start=0&site_page_count=${storeCount}`;
            break;
        case 'blogPost':
            queryString += `&blog_post_start=0&blog_post_count=${blogPostCount}`;
            break;
        case 'brands':
            queryString += `&brands_start=0&brands_count=${brandCount}`;
            break;
        default: {
            break;
        }
    }

    return queryString;
};

type filterSearchProps = {
    index: number;
    search: string;
    facetParams?: IHelloRetailSelectedFacet[];
    pageSize?: number;
    sortOrderKey?: string | null;
};

export const createSearchUrlWithFilters = ({
    index,
    search,
    facetParams,
    pageSize = PAGE_SIZE,
    sortOrderKey,
}: filterSearchProps) => {
    if (!search) {
        return null;
    }

    let tmp = `?${FILTER_KEY_SEARCH}=${search}`;

    tmp += `&${FILTER_KEY_PAGE}=${index * pageSize}&${FILTER_KEY_SIZE}=${pageSize}`;

    facetParams?.forEach((facet) => {
        if (facet.query && facet.query.includes(':')) {
            tmp += `&${facet.query?.split(':').join('=')}`;
        }
    });

    if (sortOrderKey && sortOrderKey !== DEFAULT_SORTING_HELLO_RETAIL) {
        tmp += `&${FILTER_KEY_SORT}=${sortOrderKey}`;
    }

    return sortUrlQuery(tmp);
};

export type FilterAdapter<TResult> = (result?: TResult) => IFullSearchResult;

export const facetsFromInitialData = (facets?: IHelloRetailFacetReponse[]): IHelloRetailSelectedFacet[] => {
    if (!facets) {
        return [];
    }

    const facetsWithSelection = facets.filter(
        (facet) => facet.settings?.type === 'LIST' && facet?.values?.some((value) => value.selected)
    );
    const selectedFacets: IHelloRetailSelectedFacet[] = [];
    facetsWithSelection.forEach((facet) => {
        facet.values?.forEach((value) => {
            if (value.selected) {
                selectedFacets.push({ key: value.query, title: value.title, query: value.query });
            }
        });
    });
    const priceFacet = facets.find((facet) => facet.settings?.type === 'RANGE');
    if (priceFacet && priceFacet.selectedMin && priceFacet.selectedMax) {
        selectedFacets.push({
            key: `${priceFacet.name}`,
            title: priceFacet.settings?.title,
            query: `${priceFacet.name}:${priceFacet.selectedMin},${priceFacet.selectedMax}`,
        });
    }

    return selectedFacets;
};

export const helloRetailProductFacetsAdapter = (facets?: IHelloRetailFacetReponse[]): IIndeksRetailFacetOutput[] => {
    if (!facets) {
        return [];
    }

    // TODO on sale filter?

    return (
        facets
            .map((facet) => {
                if (facet.settings?.type === 'LIST') {
                    return {
                        name: facet.settings.title,
                        control: 'Dropdown',
                        key: facet.name,
                        escapedKey: facet.name,
                        isSelected: facet?.values?.some((value) => value.selected) || false,
                        facetResults: facet?.values?.map((value) => ({
                            isSelected: value.selected,
                            count: value.count,
                            query: {
                                name: value.title,
                                value: value.query,
                                escapedValue: value.query,
                            },
                        })),
                    };
                }
                if (facet.settings?.type === 'RANGE') {
                    const max = facet?.max ? Math.round(parseFloat(facet.max)) : 0;
                    const min = facet?.min ? Math.floor(parseFloat(facet.min)) : 0;
                    const currentMax = facet?.selectedMax ? parseInt(facet.selectedMax) : undefined;
                    const currentMin = facet?.selectedMin ? parseInt(facet.selectedMin) : undefined;

                    return {
                        name: facet.settings.title,
                        control: 'Slider',
                        key: facet.name,
                        escapedKey: facet.name,
                        max: max,
                        min: min,
                        currentMax: currentMax ? (currentMax > max ? max : currentMax) : undefined,
                        currentMin:
                            currentMin !== undefined && currentMin > -1
                                ? currentMin < min
                                    ? min
                                    : currentMin
                                : undefined,
                        isSelected: (!!facet?.selectedMax && !!facet?.selectedMin) || false,
                    };
                }
                return undefined;
            })
            ?.filter(isDefined) || []
    );
};

export const helloRetailProductSortingMapper = (sortings?: IHelloRetailSorting[]): IHelloRetailSortingOption[] => {
    if (!sortings) {
        return [];
    }

    const sortingOptions: IHelloRetailSortingOption[] = [];
    sortings.forEach((sorting) => {
        sortingOptions.push({
            title: sorting.settings?.ascendingText,
            query: sorting.ascending?.query,
            selected: sorting.ascending?.selected || false,
        });
        sortingOptions.push({
            title: sorting.settings?.descendingText,
            query: sorting.descending?.query,
            selected: sorting.descending?.selected || false,
        });
    });

    return sortingOptions;
};

export const helloRetailCategoryMapper = (categories?: IHelloRetailCategory[]): ISearchContentResult => {
    if (!categories) return {} as ISearchContentResult;

    // Category data has pagenation and the total length can be found in category_results
    // But in the current setup we are showing the catories on top of the search results without pageination
    // So only the shown amount should be counted
    const data = { totalDocumentsFound: categories.length } as ISearchContentResult;

    data.content = categories.map((category) => ({
        heading: category.title,
        url: category.url,
    }));
    return data;
};

export const helloRetailArticleMapper = (
    articles?: IHelloRetailBlogPost[],
    totalResults?: number
): ISearchContentResult => {
    if (!articles) return {} as ISearchContentResult;

    const data = { totalDocumentsFound: totalResults } as ISearchContentResult;

    data.content = articles.map((article) => ({
        heading: article.title,
        url: article.url,
    }));
    return data;
};

export type HelloRetailSearchOptions = {
    productCount?: number;
    productStart?: number;
    categoryCount?: number;
    categoryStart?: number;
    blogPostCount?: number;
    blogPostStart?: number;
    sitePageCount?: number;
    sitePageStart?: number;
    brandCount?: number;
    brandStart?: number;
    filters?: string[];
    enableFilters?: boolean;
    sortOrder?: string;
};

export const createSuggestionsBody = (query: string) => {
    const searchBody = {
        query: query,
        key: helloRetailKey,
        format: 'json',
    };
    Object.assign(searchBody, {
        products: getSearchBodyByType(false, defaultSuggestionProductCount, 0, defaultProductFields),
    });
    // Object.assign(searchBody, {
    //     sitePages: getSearchBodyByType(false, defaultSuggestionSitePageCount, 0, defaultSuggestionSitePageFields),
    // });
    Object.assign(searchBody, {
        categories: getSearchBodyByType(false, defaultCategoryCount, 0, defaultCategoryFields),
    });
    Object.assign(searchBody, {
        blogPosts: getSearchBodyByType(false, defaultSuggestionBlogPostCount, 0, defaultSuggestionBlogPostFields),
    });
    Object.assign(searchBody, {
        brands: getSearchBodyByType(false, defaultSuggestionBrandCount, 0, defaultSuggestionBrandFields),
    });

    return searchBody;
};

export const createSearchBody = (
    query: string,
    searchType = defaultSearchType,
    options?: HelloRetailSearchOptions,
    userId?: string
) => {
    const searchBody = {
        query: query,
        key: helloRetailKey,
        format: 'json',
    };
    if (userId) {
        Object.assign(searchBody, {
            trackingUserId: userId,
        });
    }

    switch (searchType) {
        case 'all':
            // TODO Clean code
            Object.assign(searchBody, {
                products: getSearchBodyByType(
                    options?.enableFilters || false,
                    options?.productCount || defaultProductCount,
                    options?.productStart || 0,
                    defaultProductFields,
                    options?.filters,
                    options?.sortOrder
                ),
            });
            Object.assign(searchBody, {
                categories: getSearchBodyByType(
                    false,
                    options?.categoryCount || defaultCategoryCount,
                    options?.categoryStart || 0,
                    defaultCategoryFields
                ),
            });
            // Object.assign(searchBody, {
            //     sitePages: getSearchBodyByType(
            //         false,
            //         options?.sitePageCount || defaultSitePageCount,
            //         options?.sitePageStart || 0,
            //         defaultSitePageFields
            //     ),
            // });
            Object.assign(searchBody, {
                blogPosts: getSearchBodyByType(
                    false,
                    options?.blogPostCount || defaultBlogPostCount,
                    options?.blogPostStart || 0,
                    defaultBlogPostFields
                ),
            });
            Object.assign(searchBody, {
                brands: getSearchBodyByType(
                    false,
                    options?.brandCount || defaultBrandCount,
                    options?.brandStart || 0,
                    defaultBrandFields
                ),
            });
            break;
        case 'products':
            Object.assign(searchBody, {
                products: getSearchBodyByType(
                    options?.enableFilters || false,
                    options?.productCount || defaultProductCount,
                    options?.productStart || 0,
                    defaultProductFields,
                    options?.filters,
                    options?.sortOrder
                ),
            });
            break;
        case 'categories':
            Object.assign(searchBody, {
                categories: getSearchBodyByType(
                    false,
                    options?.categoryCount || defaultCategoryCount,
                    options?.categoryStart || 0,
                    defaultCategoryFields
                ),
            });
            // Temp hack, for some reason blogPost does not return data because of fields, but only when it is alone
            Object.assign(searchBody, {
                products: getSearchBodyByType(false, 0, 0, defaultProductFields),
            });
            break;
        case 'sitePages':
            Object.assign(searchBody, {
                sitePages: getSearchBodyByType(
                    false,
                    options?.sitePageCount || defaultSitePageCount,
                    options?.sitePageStart || 0,
                    defaultSitePageFields
                ),
            });
            break;
        case 'blogPost':
            Object.assign(searchBody, {
                blogPosts: getSearchBodyByType(
                    false,
                    options?.blogPostCount || defaultBlogPostCount,
                    options?.blogPostStart || 0,
                    defaultBlogPostFields
                ),
            });
            // Temp hack, for some reason blogPost does not return data because of fields, but only when it is alone
            Object.assign(searchBody, {
                products: getSearchBodyByType(false, 0, 0, defaultProductFields),
            });
            break;
        case 'brands':
            Object.assign(searchBody, {
                brands: getSearchBodyByType(
                    false,
                    options?.brandCount || defaultBrandCount,
                    options?.brandStart || 0,
                    defaultBrandFields
                ),
            });
            break;
        default: {
            break;
        }
    }
    return searchBody;
};

// allowed sortings, since a user can send any sorting and it would break search
export const allowedSortings = ['asc', 'desc'];
const defaultProductFields = [
    'title',
    'price',
    'priceFormatted',
    'oldPrice',
    'oldPriceFormatted',
    'currency',
    'imgUrl',
    'url',
    'originalUrl',
    'productNumber',
    'extraData.maker',
    'brand',
    'trackingCode',
    'extraData.reviewCount',
    'extraData.reviewScore',
    'extraData.itemNumber',
    'extraData.itemBinding',
    'extraData.bogformat',
    'extraData.format',
    'extraData.b2CDeliveryMethod',
    'extraData.priceTagUrl',
    'extraData.priceTagValue',
    'extraData.priceTagKey',
    'extraDataList.authors',
    'extraData.productGroupName',
];

const defaultCategoryFields = ['title', 'url', 'originalUrl', 'trackingCode'];
const defaultBlogPostFields = ['title', 'url', 'originalUrl', 'trackingCode'];
const defaultSitePageFields = ['title', 'url', 'originalUrl', 'trackingCode'];
const defaultBrandFields = ['title', 'url', 'originalUrl', 'trackingCode'];

const defaultSuggestionProductFields = [
    'title',
    'price',
    'priceFormatted',
    'oldPrice',
    'oldPriceFormatted',
    'url',
    'originalUrl',
    'imgUrl',
    // 'inStock',
    // 'brand',
    'currency',
    // 'description',
    // 'ean',
    // 'keyWords',
    'productNumber',
    'isOnSale',
    'trackingCode',
    'extraData.b2CDeliveryMethod',
    // 'extraData.edition',
    'extraData.format',
    // 'extraData.isbn',
    // 'extraData.language',
    // 'extraData.numberOfPages',
    // 'extraData.originalLanguage',
    'extraData.priceTagKey',
    'extraData.priceTagValue',
    'extraData.priceTagUrl',
    // 'extraData.productGroupId',
    'extraData.productGroupName',
    // 'extraData.publisher',
    // 'extraData.reviewCount',
    // 'extraData.reviewScore',
    'extraData.showDiscount',
    'extraDataList.authors',
    // 'extraDataList.series',
    // 'extraDataList.themaTitles',
];
const defaultSuggestionBlogPostFields = ['title', 'url', 'originalUrl', 'trackingCode'];
// const defaultSuggestionSitePageFields = ['title', 'url', 'originalUrl', 'trackingCode'];
const defaultSuggestionBrandFields = ['title', 'url', 'originalUrl', 'trackingCode'];

const getSearchBodyByType = (
    withFilters = false,
    count: number,
    start?: number,
    fields?: string[],
    filters?: string[],
    sorting?: string
) => {
    return {
        start: start || 0,
        count: count,
        fields: fields || [],
        ...(filters && { filters: filters }),
        ...(sorting && { sorting: [sorting] }),
        ...(withFilters && { returnFilters: true }),
    };
};

const getDeliveryMethod = (b2CDeliveryMethod?: string) => {
    if (!b2CDeliveryMethod) {
        return undefined;
    }
    try {
        return parseInt(b2CDeliveryMethod);
    } catch (error) {
        return undefined;
    }
};

/*
 * Used for mapping regular search in search overlay
 */
export const mapHelloRetailToIndeksRetailProduct = (data?: IHelloRetailProduct[], shouldFormatPrice?: boolean) => {
    const products: ICombinedHelloRetailProduct[] =
        data?.map((p) => ({
            id: p?.productNumber,
            name: p?.title,
            nameClean: p?.title,
            itemBinding: p?.extraData?.format,
            maker: p.extraDataList?.authors?.length
                ? p.extraDataList.authors.join(' & ')
                : p?.extraData?.productGroupName
                ? p.extraData.productGroupName
                : p?.brand,
            productUrl: p?.originalUrl,
            // productUrl: p?.url,
            primaryImageUrl: p?.imgUrl,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            b2CDeliveryMethod: getDeliveryMethod(p.extraData?.b2CDeliveryMethod) as any,
            numberOfReviews: parseInt(p?.extraData?.reviewCount || '0'),
            reviewScore: parseInt(p.extraData.reviewScore || '0'),
            itemNumber: p?.productNumber,
            authors: p.extraDataList?.authors,
            vareGruppe: p?.extraData?.productGroupName,
            resourceID: p?.imgUrl?.match(/\/([\w-]+)\.webp/)?.[1],

            isSpecialPrice: p.extraData?.showDiscount === 'true',
            itemPriceTagUrl:
                p.extraData?.priceTagUrl && p.extraData?.priceTagKey ? p.extraData?.priceTagUrl : undefined,
            trackingCode: p?.trackingCode,
            productNumber: p?.productNumber,

            currency: p.currency,
            format: p.extraData?.format,
            onSale: p.isOnSale,
            hasSpecialPrice: p.extraData?.showDiscount === 'true',
            splashes: p.extraData?.priceTagUrl ? [p.extraData?.priceTagUrl] : [],
            ...(shouldFormatPrice
                ? {
                      price: formatPriceFromHelloRetail(p.price) as number,
                      priceFormatted: `${formatPriceFromHelloRetail(p.price)} ${p.currency?.toUpperCase()}`,
                      oldPrice: formatPriceFromHelloRetail(p.oldPrice) as number,
                      oldPriceFormatted: `${formatPriceFromHelloRetail(p.oldPrice)} ${p.currency?.toUpperCase()}`,
                      pricesSanitized: {
                          ...(p?.oldPrice && {
                              retailPrice: `${
                                  p?.oldPriceFormatted || formatPriceFromHelloRetail(p?.oldPrice)
                              } ${p?.currency?.toUpperCase()}`,
                              retailPriceAmount: formatPriceFromHelloRetail(p?.oldPrice) as number,
                          }),
                          actualPrice: `${
                              p?.priceFormatted || formatPriceFromHelloRetail(p?.price)
                          } ${p?.currency?.toUpperCase()}`,
                          actualPriceAmount: formatPriceFromHelloRetail(p.price) as number,
                      },
                  }
                : {
                      price: p.price,
                      priceFormatted: `${p.price} ${p.currency?.toUpperCase()}`,
                      oldPrice: p.oldPrice,
                      oldPriceFormatted: `${p.oldPrice} ${p.currency?.toUpperCase()}`,
                      pricesSanitized: {
                          ...(p?.oldPrice && {
                              retailPrice: `${p?.oldPriceFormatted || p?.oldPrice} ${p?.currency?.toUpperCase()}`,
                              retailPriceAmount: p?.oldPrice,
                          }),
                          actualPrice: `${p?.priceFormatted || p?.price} ${p?.currency?.toUpperCase()}`,
                          actualPriceAmount: p.price,
                      },
                  }),
        })) || [];
    return products;
};

export const createInitialContentBody = (userId?: string) => {
    const searchBody = {
        query: '*',
        key: helloRetailKey,
        format: 'json',
        products: {
            fields: defaultSuggestionProductFields,
            returnInitialContent: true,
        },
        categories: {
            fields: defaultCategoryFields,
            returnInitialContent: true,
        },
    };
    if (userId) {
        Object.assign(searchBody, {
            trackingUserId: userId,
        });
    }
    return searchBody;
};

export const mapSearchIndexSize = (searchType: SearchContentTypes, index: number, size: number) => {
    switch (searchType) {
        case 'all':
            return {
                productCount: size,
                productStart: index,
                blogPostCount: size,
                blogPostStart: index,
                categoryCount: size,
                categoryStart: index,
                // Remove stores from search
                // sitePageCount: size,
                // sitePageStart: index,
                brandCount: size,
                brandStart: index,
            };
        case 'products':
            return {
                productCount: size,
                productStart: index,
            };
        case 'categories':
            return {
                categoryCount: size,
                categoryStart: index,
            };

        case 'blogPost':
            return {
                blogPostCount: size,
                blogPostStart: index,
            };
        case 'sitePages':
            return {
                sitePageCount: size,
                sitePageStart: index,
            };
        case 'brands':
            return {
                brandCount: size,
                brandStart: index,
            };
        default: {
            return null;
        }
    }
};

export const convertToHelloRetailSSRUrl = (urlString: string, contentType: SearchContentTypes, userId?: string) => {
    const url = new URL(urlString);

    const parsedUrlQuery = querystring.parse(url.search.substring(1));

    const queries = getFacetParamsFromUrl(parsedUrlQuery);

    let type: SearchContentTypes = SearchContentTypes.All;
    if (isDefined(queries[FILTER_KEY_TYPE])) {
        type = (
            Array.isArray(queries[FILTER_KEY_TYPE]) ? queries[FILTER_KEY_TYPE]?.[0] : queries[FILTER_KEY_TYPE]
        ) as SearchContentTypes;
        delete queries[FILTER_KEY_TYPE];
    }
    if (isDefined(queries[FILTER_KEY_CONTENT_TYPES])) {
        type = (
            Array.isArray(queries[FILTER_KEY_CONTENT_TYPES])
                ? queries[FILTER_KEY_CONTENT_TYPES]?.[0]
                : queries[FILTER_KEY_CONTENT_TYPES]
        ) as SearchContentTypes;
        delete queries[FILTER_KEY_CONTENT_TYPES];
    }
    if (!type) {
        type = contentType;
    }

    const page = Number(queries[FILTER_KEY_PAGE]) || 0;
    delete queries[FILTER_KEY_PAGE];

    const size = Number(queries[FILTER_KEY_SIZE]) || PAGE_SIZE;

    delete queries[FILTER_KEY_SIZE];

    let sortOrder = undefined;
    if (isDefined(queries[FILTER_KEY_SORT])) {
        sortOrder = (
            Array.isArray(queries[FILTER_KEY_SORT]) ? queries[FILTER_KEY_SORT]?.[0] : queries[FILTER_KEY_SORT]
        ) as string;
        delete queries[FILTER_KEY_SORT];
        if (sortOrder === DEFAULT_SORTING_HELLO_RETAIL) {
            sortOrder = undefined;
        }
        const isValidSortOrder = sortOrder?.includes(allowedSortings[0]) || sortOrder?.includes(allowedSortings[1]);

        if (sortOrder && !isValidSortOrder) {
            sortOrder = undefined;
        }
    }

    const searchQuery = (
        Array.isArray(queries[FILTER_KEY_SEARCH]) ? queries[FILTER_KEY_SEARCH]?.[0] : queries[FILTER_KEY_SEARCH]
    ) as string;
    delete queries[FILTER_KEY_SEARCH];

    const indexAndSize = mapSearchIndexSize(type, page, size);
    if (!indexAndSize) {
        console.error('No index and size found');
        return new Promise<IHelloRetailSearchResponse>((resolve) => resolve({ success: false }));
    }

    const mappedFilters: string[] = [];
    Object.entries(queries).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.map((singleValue) => mappedFilters.push(`${key}:${singleValue}`));
        } else {
            mappedFilters.push(`${key}:${value}`);
        }
    });

    const searchBody = createSearchBody(
        searchQuery,
        type,
        {
            ...indexAndSize,
            sortOrder: sortOrder || undefined,
            filters: mappedFilters,
            enableFilters: type === SearchContentTypes.Products || type === SearchContentTypes.All,
        },
        userId
    );

    const searchUrl = process.env.API_PROXY_SEARCH;

    if (!searchUrl) {
        console.error('No search url found in env');
        return new Promise<IHelloRetailSearchResponse>((resolve) => resolve({ success: false }));
    }

    return fetcher<IHelloRetailSearchResponse>(searchUrl, {
        body: JSON.stringify(searchBody),
        method: 'POST',
    });
};

/*
 *   Deprecated mappings and logic
 */

export const mapHelloRetailSearchSuggestionProducts = (data?: IHelloRetailProduct[]) => {
    const products: IMappedHelloRetailSuggestionProduct[] =
        data?.map((p) => ({
            productUrl: p?.originalUrl,
            // productUrl: p?.url,
            name: p?.title,
            primaryImageUrl: p?.imgUrl,
            productNumber: p?.productNumber,
            authors: p.extraDataList?.authors,
            vareGruppe: p?.extraData?.productGroupName,
            resourceID: p?.imgUrl?.match(/\/([\w-]+)\.webp/)?.[1],
            price: p.price,
            priceFormatted: p.priceFormatted ? `${p.priceFormatted} ${p.currency?.toUpperCase()}` : undefined,
            oldPrice: p.oldPrice,
            oldPriceFormatted: p.oldPriceFormatted ? `${p.oldPriceFormatted} ${p.currency?.toUpperCase()}` : undefined,
            currency: p.currency,
            format: p.extraData?.format,
            onSale: p.isOnSale,
            itemBinding: p?.extraData?.format,
            // hasSpecialPrice: p.extraData?.showDiscount === 'true',
            b2CDeliveryMethod: getDeliveryMethod(p.extraData?.b2CDeliveryMethod),
            itemPriceTagUrl:
                p.extraData?.priceTagUrl && p.extraData?.priceTagKey ? p.extraData?.priceTagUrl : undefined,
            trackingCode: p?.trackingCode,
        })) || [];
    return products;
};

export const mapHelloRetailInitialContent = (data?: IHelloRetailProduct[]) => {
    const products: IMappedHelloRetailSuggestionProduct[] =
        data?.map((p) => ({
            productUrl: p?.originalUrl,
            // productUrl: p?.url,
            name: p?.title,
            primaryImageUrl: p?.imgUrl,
            productNumber: p?.productNumber,
            authors: p.extraDataList?.authors,
            resourceID: p?.imgUrl?.match(/\/([\w-]+)\.webp/)?.[1],
            price: p.price,
            priceFormatted: `${p.price} ${p.currency?.toUpperCase()}`,
            oldPrice: p.oldPrice,
            oldPriceFormatted: `${p.oldPrice} ${p.currency?.toUpperCase()}`,
            currency: p.currency,
            vareGruppe: p?.extraData?.productGroupName,
            format: p.extraData?.format,
            onSale: p.isOnSale,
            itemBinding: p?.extraData?.format,
            hasSpecialPrice: p.extraData?.showDiscount === 'true',
            b2CDeliveryMethod: getDeliveryMethod(p.extraData?.b2CDeliveryMethod),
            splashes: p.extraData?.priceTagUrl ? [p.extraData?.priceTagUrl] : [],
            trackingCode: p?.trackingCode,
        })) || [];
    return products;
};

export const mapHelloRetailProducts = (data?: IHelloRetailProduct[], shouldFormatPrice?: boolean) => {
    const products: IMappedHelloRetailProduct[] =
        data?.map((p) => ({
            productUrl: p?.originalUrl,
            // productUrl: p?.url,
            name: p?.title,
            primaryImageUrl: p?.imgUrl,
            productNumber: p?.productNumber,
            // maker: p.extraDataList?.authors?.length
            //     ? p.extraDataList.authors.join(', ')
            //     : p?.brand
            //     ? p.brand
            //     : p.extraData.productGroupName,
            maker: p.extraDataList?.authors?.length
                ? p.extraDataList.authors.join(' & ')
                : p?.extraData?.productGroupName
                ? p.extraData.productGroupName
                : p?.brand,
            authors: p.extraDataList?.authors,
            vareGruppe: p?.extraData?.productGroupName,
            resourceID: p?.imgUrl?.match(/\/([\w-]+)\.webp/)?.[1],
            numberOfReviews: parseInt(p?.extraData?.reviewCount || '0'),
            reviewScore: parseInt(p.extraData.reviewScore || '0'),
            id: p?.productNumber,
            isSpecialPrice: p.extraData?.showDiscount === 'true',
            itemBinding: p?.extraData?.format,
            b2CDeliveryMethod: getDeliveryMethod(p.extraData?.b2CDeliveryMethod),
            itemPriceTagUrl:
                p.extraData?.priceTagUrl && p.extraData?.priceTagKey ? p.extraData?.priceTagUrl : undefined,
            trackingCode: p?.trackingCode,
            ...(shouldFormatPrice
                ? {
                      pricesSanitized: {
                          ...(p?.oldPrice && {
                              retailPrice: `${
                                  p?.oldPriceFormatted || formatPriceFromHelloRetail(p?.oldPrice)
                              } ${p?.currency?.toUpperCase()}`,
                              retailPriceAmount: formatPriceFromHelloRetail(p?.oldPrice) as number,
                          }),
                          actualPrice: `${
                              p?.priceFormatted || formatPriceFromHelloRetail(p?.price)
                          } ${p?.currency?.toUpperCase()}`,
                          actualPriceAmount: formatPriceFromHelloRetail(p.price) as number,
                      },
                  }
                : {
                      pricesSanitized: {
                          ...(p?.oldPrice && {
                              retailPrice: `${p?.oldPriceFormatted || p?.oldPrice} ${p?.currency?.toUpperCase()}`,
                              retailPriceAmount: p?.oldPrice,
                          }),
                          actualPrice: `${p?.priceFormatted || p?.price} ${p?.currency?.toUpperCase()}`,
                          actualPriceAmount: p.price,
                      },
                  }),
        })) || [];
    return products;
};
